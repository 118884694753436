import React from 'react'
import { Admin, Resource } from 'react-admin'
import restProvider from 'ra-data-simple-rest'
import NoMenuLayout from './NoMenuLayout'
import authProvider from './auth/AuthProvider'
import httpClient from './auth/HttpClient'
import PIQAErrorList from './piqa_error/PIQAErrorList'
import PIQAErrorEdit from './piqa_error/PIQAErrorEdit'
import './App.css'

const dataProvider = restProvider(process.env.REACT_APP_API_URL, httpClient);

function App() {
    return (
        <Admin layout={NoMenuLayout} authProvider={authProvider} dataProvider={dataProvider} disableTelemetry>
            <Resource name="piqa-error" list={PIQAErrorList} edit={PIQAErrorEdit} />
            <Resource name="family" />
            <Resource name="attribute-set" />
            <Resource name="brand" />
            <Resource name="error-code" />
            <Resource name="source" />
        </Admin>
    )
}

export default App
