import { jwtDecode } from "jwt-decode"

const authProvider = {
    login: ({ username, password }) => {
        const request = new Request(`${process.env.REACT_APP_API_URL}/token`, {
            method: 'POST',
            body: `grant_type=password&username=${username}&password=${password}`,
            headers: new Headers({ 'Content-Type': 'application/x-www-form-urlencoded' }),
        })
        return fetch(request)
            .then(response => {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText)
                }
                return response.json()
            })
            .then(response_json => {
                localStorage.setItem('jwt', response_json.access_token)
            })
            .catch(error => {
                if (error.message === 'Unauthorized')
                    throw new Error('Invalid credentials')
                throw new Error('Network error')
            })
    },
    checkError: (error) => {
        const status = error.status
        if (status === 401 || status === 403) {
            localStorage.removeItem('jwt')
            return Promise.reject({ message: false })
        }
        return Promise.resolve()
    },
    checkAuth: () => localStorage.getItem('jwt')
        ? Promise.resolve()
        : Promise.reject(),
    getIdentity: () => {
        const decoded = jwtDecode(localStorage.getItem('jwt'))
        return Promise.resolve({ fullName: decoded.sub })
    },
    getPermissions: params => Promise.resolve(),
    logout: () => {
        localStorage.removeItem('jwt')
        return Promise.resolve()
    }
}

export default authProvider