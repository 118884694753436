import React from 'react'
import {
    Button,
    CheckboxGroupInput,
    Edit,
    FormDataConsumer,
    NumberInput,
    RadioButtonGroupInput,
    SaveButton,
    SimpleForm,
    Toolbar,
    useNotify,
    useRedirect,
    useRefresh,
} from 'react-admin'
import Box from '@mui/material/Box'
import Chip from '@mui/material/Chip'
import { Editor } from '@tinymce/tinymce-react';
import { styled } from '@mui/material/styles';
import httpClient from '../auth/HttpClient';

// MUI styles adaptation
const StyledButton = styled(Button)(({ theme }) => ({
    marginRight: theme.spacing(0.5),
    marginTop: theme.spacing(0.5),
    '& .MuiButton-label > *': {
        paddingLeft: 0,
    },
}));

const useGlobalStyles = () => ({
    '@global': {
        'fieldset[class*="MuiFormControl-root"]': {
            width: "100%"
        },
        'span[class*="MuiTypography-root"]': {
            width: "100%"
        },
    }
});

const ImgSaveButton = (props) => {
    const {
        redirect,
        handleSubmitWithRedirect,
        ...rest
    } = props;

    const classes = useGlobalStyles()
    const notify = useNotify()
    const redirectCall = useRedirect()

    const save = (record, error_id) => httpClient(new URL("/piqa-error/"+error_id, process.env.REACT_APP_API_URL), {
        method: "PUT",
        body: JSON.stringify(record)
    })
    .then(() => {
        notify("Proposition saved", "info")
    })
    .catch(e => {
        notify("Erreur lors du save de la proposition", "warning")
    })

    return <>
        <StyledButton onClick={(e) => {
            e.preventDefault();
            let record = props.record
            let proposals = []
            
            Object.entries(record._proposals_positions).forEach(element => {
                proposals.push({uid: element[0], position: element[1]})
            });

            proposals.sort((a,b) => {
                if (a.position < b.position) return -1
                if (a.position > b.position) return 1
                return 0
            })

            record.selected_proposals = []
            proposals.forEach(element => {
                record.selected_proposals.push(element.uid)
            })
            save(record, record.id)
            props.record.proposals = []
            redirectCall(redirect)            
        }} label="Save" color="primary" variant="contained" className={classes.skip} size="large" />
    </>
}

const SkipButton = (props) => {
    const classes = useGlobalStyles()
    const notify = useNotify()
    const refresh = useRefresh()

    const skip = (entity_id, error_code) => httpClient(new URL("/skip-proposal", process.env.REACT_APP_API_URL), {
        method: "POST",
        body: JSON.stringify({ entity_id, error_code})
    })
    .then(() => {
        refresh()
        notify("Proposition skipped", "info")
    })
    .catch(e => {
        refresh()
        notify("Erreur lors du skip de la proposition", "warning")
    })

    return <>
        <StyledButton onClick={(e) => {
            e.preventDefault();

            skip(props.record.entity_id, props.record.error_code)
        }} label="Skip" color="primary" variant="contained" className={classes.skip} size="large" />
    </>
}

const ToolbarSkipButton = ({
  handleSubmit,
  handleSubmitWithRedirect,
  onSave,
  invalid,
  pristine,
  saving,
  submitOnEnter,
  ...rest
}) => <SkipButton {...rest} />;

const ProductEditToolbar = props => {
    if (props.record.error_code === 'description_too_short') {
        return (
            <Toolbar {...props} >
                <SaveButton  />
                <ToolbarSkipButton />
            </Toolbar>
        )
    } 
    return (
        <Toolbar {...props} >
            <ImgSaveButton  />
            <ToolbarSkipButton />
        </Toolbar>
    )
}

const validateProposals = (values) => {
    const errors = {}

    switch (values.error_code) {
        case 'description_too_short':
            if (!values.selected_proposals)
                errors.selected_proposals = 'You must select one proposal before saving.'
            else if (values.selected_proposals === 'custom' && !values.custom)
                errors.custom = 'Custom proposal must not be empty'
            break
        case 'img_miss':
        case 'img_alone':
        case 'img_low_res':
        case 'img_ridiculous_res':
        case 'img_to_review':
        case 'img_manually_to_review':
            if (values.selected_proposals.length === 0)
                errors.selected_proposals = 'You must select one proposal before saving.'
            break
        default:
    }

    return errors
}

const transformPIQAError = error => {
    if (['img_miss', 'img_alone', 'img_low_res', 'img_ridiculous_res', 'img_to_review', 'img_manually_to_review'].includes(error.error_code)) {
        error.selected_proposals.sort((a, b) => error._proposals_positions[a] - error._proposals_positions[b])
    } else {
        error.selected_proposals = [error.selected_proposals]
    }

    return error
}

const ProductEdit = (props) => {
    const classes = useGlobalStyles()
    const notify = useNotify()
    const refresh = useRefresh()
    const errorCode = props.record.error_code
    let proposals = []
    let ErrorField = null

    const ExcludeButtons = ({ record }) => {
        const exclude = (entity_id) => httpClient(new URL("/exclude-proposal", process.env.REACT_APP_API_URL), {
                method: "POST",
                body: JSON.stringify({ uid: record.uid, error_code: props.record.error_code, entity_id })
            })
            .then(() => {
                refresh()
                notify("Proposition exclue", "info")
            })
            .catch(e => {
                refresh()
                notify(e.status === 404 ? "L'exclusion existe déjà" : "Erreur lors de l'exclusion", "warning")
            })

        return <>
            <StyledButton onClick={(e) => {
                e.preventDefault();
                exclude();
            }} label="Exclure" color="secondary" variant="outlined" className={classes.button} />
            <StyledButton onClick={(e) => {
                e.preventDefault();
                exclude(props.record.entity_id);
            }} label="Exclure Globalement" color="secondary" variant="outlined" className={classes.button} />
        </>
    }

    const ImageChoiceField = ({ record }) => (
        <Box border={1} borderRadius="borderRadius" borderColor="grey.200" display="flex" flexDirection="column" alignItems="center" p={.5}>
            <Box display="flex" justifyContent="space-between" width="100%">
                <Chip label={record.source} color="primary" size="small" />
                <FormDataConsumer>
                    {({ formData,...rest }) => {
                        let default_position = 0
                        let uid = record.uid
                        if (formData.selected_proposals.includes(uid)) {
                            default_position = formData.selected_proposals.indexOf(uid)
                        }

                        if (!formData.selected_proposals.includes(uid) && Object.keys(formData._proposals_positions).includes(uid)) {
                            delete formData._proposals_positions[uid]
                        }

                        props.record._proposals_positions = formData._proposals_positions

                        return (formData.selected_proposals.includes(uid) &&
                        <NumberInput label="Position" source={'_proposals_positions[' + uid + ']'} step={1} min={1} max={10} defaultValue={default_position} />)
                        }
                    }
                </FormDataConsumer>
            </Box>
            <img src={record.url} alt={record.source} height="200vh" width="200vw" />
            {record.resolution}x{record.resolution}
            <ExcludeButtons record={record} />
        </Box>
    )

    const DescriptionField = ({ record }) => {
        if (record.source !== 'custom') {
            return (
                <div className={classes.radio}>
                    <Chip label={record.source} color="primary" size="small" className={classes.chip}/>
                    <div>{record.description}</div>
                    <div>
                        <StyledButton onClick={(e) => {
                            e.preventDefault();
                            navigator.clipboard.writeText(record.description);
                            return false;
                        }} label="Copier" variant="contained" className={classes.button} />
                        <ExcludeButtons record={record} />
                    </div>
                </div>
            )
        } else {
            return (<span>Custom</span>)
        }
    }

    switch (errorCode) {
        case 'description_too_short':
            proposals = [...props.record.proposals, { source: 'custom', value: 'Custom', uid: 'custom' }]
            const defaultProposal = proposals.length === 2 ? proposals[0]['uid'] : null

            ErrorField = ({ record }) => (
                <>
                    <RadioButtonGroupInput
                        label={false}
                        source='selected_proposals'
                        choices={proposals}
                        optionText={<DescriptionField />}
                        optionValue='uid'
                        initialValue={defaultProposal}
                        row={false}
                    />
                    <Editor
                        apiKey={ process.env.REACT_APP_TINYMCE_API_KEY }
                        init={{
                            height: 300,
                            menubar: false,
                            block_formats: 'Paragraph=p;Header 3=h3;Header 4=h4;Header 5=h5;Header 6=h6',
                            entity_encoding : 'raw',
                            plugins: [ 'lists', 'code', 'paste code wordcount' ],
                            toolbar: 'undo redo | formatselect | bold italic | bullist numlist | removeformat | code ',
                            content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                        }}
                        onEditorChange={(value, editor) => { record.custom = value }}
                    />
                </>
            )
            break
        case 'img_miss':
        case 'img_alone':
        case 'img_low_res':
        case 'img_ridiculous_res':
        case 'img_to_review':
        case 'img_manually_to_review':
            proposals = props.record.proposals.sort(
                (a, b) => (a.source === 'Spacefoot' && b.source !== 'Spacefoot') ? -1 : (b.source === 'Spacefoot' && a.source  !== 'Spacefoot') ? 1 : 0
            )
            props.record.selected_proposals = props.record.proposals.filter(v => v.source === 'Spacefoot').map(v => v.uid)
            props.record._proposals_positions = {}
            ErrorField = () => (
                <CheckboxGroupInput
                    label={false}
                    source='selected_proposals'
                    choices={proposals}
                    optionText={<ImageChoiceField />}
                    optionValue="uid"
                />
            )
            break
        default:
            console.log(errorCode)
            console.log('fuck!?')
    }

    const PIQAErrorTitle = ({ record }) => {
        return <span>{record ? `: ${record.mpn}` : ''}</span>;
    };

    return (
        <Edit {...props} title={<PIQAErrorTitle />} mutationMode='pessimistic' transform={transformPIQAError}>
            <SimpleForm
                resource='products'
                toolbar={<ProductEditToolbar />}
                validate={validateProposals}
            >
                <ErrorField />
            </SimpleForm>
        </Edit>
    )
}

export default ProductEdit
